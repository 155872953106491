import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";

///Add Question demo
import AddQuestions from "./components/AddQuestions/AddQuestions"

///E-library
import ContentList from "./components/E-library/ContentList"
import ElibraryContentRequestDetails from './components/E-library/ElibraryContentRequestDetails';
import ElibraryDetailsDesignerContentRequest from './components/E-library/ElibraryDetailsDesignerContentRequest';
import PreviewElibraryPdf from './components/E-library/PreviewElibraryPdf';
import PdfViewElibraryDam from './components/E-library/PdfViewElibraryDam';

///Question
import QuestionsList from "./components/Questions/QuestionsList";
import ViewQuestionDetails from "./components/Questions/ViewQuestionDetails";
import QuestionRequestDetails from "./components/Questions/QuestionRequestDetails";
import UploadQuestions from "./components/Questions/UploadQuestions";
import UpdateQuestions from "./components/Questions/UpdateQuestions";

///My Earning
import MyEarning from "./components/Earning/MyEarning";


import Profile from "./components/Dashboard/Profile";
import ChangePassword from "./components/Dashboard/ChangePassword";
import ResetPassword from "./pages/ResetPassword";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";

import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {

  const { menuToggle } = useContext(ThemeContext);

  const routes = [

    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// Profile
    { url: "profile", component: Profile },
    { url: "change-password", component: ChangePassword },
    
    /// pages
    { url: "page-register", component: Registration },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "reset-password", component: ResetPassword },

    ///Add Question
    { url: "add-questions", component: AddQuestions },

    { url: "questions-list", component: QuestionsList },
    { url: "view-question-details", component: ViewQuestionDetails },
    { url: "question-request-details", component: QuestionRequestDetails },
    { url: "upload-questions", component: UploadQuestions },
    { url: "update-questions", component: UpdateQuestions },

    ///E-library
    { url: "content-list", component: ContentList },
    { url: "content-request-details", component: ElibraryContentRequestDetails },
    { url: "designer-request-details", component: ElibraryDetailsDesignerContentRequest },
    { url: "preview-pdf", component: PreviewElibraryPdf },
    { url: "elibrary-content-pdf-view/:infoid", component: PdfViewElibraryDam},

    //My Earning
    { url: "my-earning", component: MyEarning },
   

  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;


