import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import ImageUploading from "react-images-uploading";
import { Alert, Button, Modal } from "react-bootstrap";
import axios from "axios";

import { uploadFile, loadingQuestionAction } from '../../../store/actions/QuestionsAction';
import { checkVerifi } from '../../../store/actions/CheckVerifiAction';
import { getExamCategory } from '../../../store/actions/ExamCategoryAction';
import { getBranchDetails } from '../../../store/actions/BranchActions';
import { getChaptersData } from '../../../store/actions/ChapterAction';
import { getBoardDetails } from '../../../store/actions/BoardActions';
import { getExamTypeDetails } from '../../../store/actions/ExamTypeActions';
import { getClassData } from '../../../store/actions/ClassAction';
import emtyImg from '../../../images/no-image.png';

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const API_URL = "https://api.schemaphic.co.in:3000/api/superadmin/uploadimage";

const AddQuestions = (props) => {

	const dispatch = useDispatch();
	const fileRef = useRef();
	const imgRef = useRef();
	let errorsObj = { category: '', branch: '', chapter: '', examType: '', class: '', questiontype: '', questiono: '', questionbody: '', optionA: '', optionB: '', optionC: '', optionD: '', answer: '', reason: '' };
	const [qestionDetails, setQestionDetails] = useState({
		category: '',
		branch: '',
		chapter: '',
		examType: '',
		classNo: '',
		questiontype: '',
		questiono: '',
		questionbody: '',
		optionA: '',
		optionB: '',
		optionC: '',
		optionD: '',
		answer: '',
		reason: ''
	})
	// const categoryRef = useRef();
	const [errors, setErrors] = useState(errorsObj);
	const [isCheck, setIsCheck] = useState(false);
	// const [category, setCategory] = useState('');
	// const [examtype, setExamtype] = useState('');
	// const [classroom, setClassroom] = useState('');
	const [branch, setBranch] = useState('');
	const [chapter, setChapter] = useState('');
	const [questiontype, setQuestiontype] = useState('');
	const [questiono, setQuestiono] = useState('');
	// const [questiobody, setQuestiobody] = useState('');
	const [option, setOption] = useState('');
	const [option2, setOption2] = useState('');
	const [option3, setOption3] = useState('');
	const [option4, setOption4] = useState('');
	const [answers, setAnswers] = useState('');
	const [answersbody, setAnswersbody] = useState('');
	const [file, setFile] = useState([]);
	const [images, setImages] = useState([]);
	const [imagesArray, setImagesArray] = useState([]);
	const [questionArray, setQuestionArray] = useState([]);
	const [reasonArray, setReasonArray] = useState([]);
	const [firstOptionArray, setFirstOptionArray] = useState([]);
	const [secondOptionArray, setSecondOptionArray] = useState([]);
	const [thirdOptionArray, setThirdOptionArray] = useState([]);
	const [fourthOptionArray, setFourthOptionArray] = useState([]);
	const [modal, setModal] = useState(false);
	const [htmlResult, setHtmlResult] = useState('');
	// const [count, setCount] = useState(0);
	// const [reasonCount, setReasonCount] = useState(0);
	// const [firstOptionCount, setFirstOptionCount] = useState(0);
	// const [secondOptionCount, setSecondOptionCount] = useState(0);
	// const [thirdOptionCount, setThirdOptionCount] = useState(0);
	// const [fourthOptionCount, setFourthOptionCount] = useState(0);
	// const [questionEditor, setQuestionEditor] = useState(false);
	// const [confirmModal, setConfirmModal] = useState(false);
	// const [activeNumber, setActiveNumber] = useState(0);
	// const [optionAEditor, setOptionAEditor] = useState(false);
	// const [optionBEditor, setOptionBEditor] = useState(false);
	// const [optionCEditor, setOptionCEditor] = useState(false);
	// const [optionDEditor, setOptionDEditor] = useState(false);
	// const [reasonEditor, setReasonEditor] = useState(false);

	const [questionbody, setQuestionbody] = useState('');
	const [classNo, setClassNo] = useState('')
	const [optionA, setOptionA] = useState('')
	const [optionB, setOptionB] = useState('')
	const [optionC, setOptionC] = useState('')
	const [optionD, setOptionD] = useState('')
	const [answer, setAnswer] = useState('')
	const [reason, setReason] = useState('')
	const [selectDefaultValue, setSelectDefaultValue] = useState('option')


	useEffect(() => {
		dispatch(getExamCategory(props.history));
		dispatch(getBranchDetails(props.history));
		dispatch(getClassData(props.history));
	}, []);

	/* state data updated */
	const setdata = (e) => {
		if (e.target.name == 'branch') {
			dispatch(getChaptersData(e.target.value.split('^')[0]));
		}
		if (e.target.name == 'category') {
			if (e.target.value == '') {
				console.log("You have select blank")
				setQestionDetails({
					...qestionDetails,
					examType: '',
					classNo: ''
				})
			}
			if (e.target.value == 1) {
				setQestionDetails({
					...qestionDetails,
					examType: '',
					classNo: ''
				})
				dispatch(getBoardDetails(props.history));
			} else if (e.target.value == 2) {

				setQestionDetails({
					...qestionDetails,
					examType: '',
					classNo: ''
				})
				dispatch(getExamTypeDetails(e.target.value, props.history));
			}
		}
		if (e.target.name == 'examType') {

			if (qestionDetails.category == 2 && e.target.value == 1) {
				setQestionDetails({
					...qestionDetails,
					classNo: ''
				})
			}
		}

		const { name, value } = e.target
		setQestionDetails((preValue) => {
			return {
				...preValue,
				[name]: value
			};
		})
	}

	/* form submit */
	function onSubmit(e) {
		e.preventDefault();

		const { category, branch, chapter, examType, classNo, questiontype, questiono, answer, } = qestionDetails;

		let error = false;
		const errorObj = { ...errorsObj };

		if (category === '') {
			errorObj.category = 'Exam category is required';
			error = true;
		}

		if (examType === '') {
			errorObj.examType = 'Exam type is Required';
			error = true;
		}
		if ((qestionDetails.category == 2 && qestionDetails.examType == 2)  || (qestionDetails.category == 1 && qestionDetails.examType == 1 || qestionDetails.examType ==  2) ) {
			if (classNo === '') {
				errorObj.class = 'Class is Required';
				error = true;
			}
		}

		if (branch === '') {
			errorObj.branch = 'Branch is Required';
			error = true;
		}

		if (chapter === '') {
			errorObj.chapter = 'Chapter is Required';
			error = true;
		}
		if (questiontype === '') {
			errorObj.questiontype = 'Qestion Type is Required';
			error = true;
		}
		if (questiono === '') {
			errorObj.questiono = 'Qestion no is Required';
			error = true;
		}
		if (questionbody === '') {
			errorObj.questionbody = 'Question is Required';
			error = true;
		}
		if (optionA === '') {
			errorObj.optionA = 'Option A is Required';
			error = true;
		}
		if (optionB === '') {
			errorObj.optionB = 'Option B is Required';
			error = true;
		}
		if (optionC === '') {
			errorObj.optionC = 'Option C is Required';
			error = true;
		}
		if (optionD === '') {
			errorObj.optionD = 'Option D is Required';
			error = true;
		}

		if (answer === '') {
			errorObj.answer = 'Answer is Required';
			error = true;
		}
		if (reason === '') {
			errorObj.reason = 'Reason is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		console.log("questionbody-------", questionbody)

		// dispatch(checkVerifi());
		// dispatch(loadingQuestionAction(true));
		// dispatch(uploadFile(category, branch.split('^')[0], branch.split('^')[1], chapter.split('^')[0], chapter.split('^')[1], isCheck ? 2 : 1, file, props.history));



		/* dispatch(checkVerifi());
		dispatch(loadingQuestionAction(true));
		dispatch(uploadFile(category, branch.split('^')[0], branch.split('^')[1], chapter.split('^')[0], chapter.split('^')[1], isCheck ? 2 : 1, file, props.history));
		setCategory('');
		setBranch('');
		setChapter('');
		setIsCheck(false);
		setFile(''); */
	}


	/* resone preview */
	const onReasonPreviewHandler = () => {
		setModal(true);
		let str = answersbody;
		reasonArray.forEach((item, index) => {
			str = str.replace(`[${item.name}]`, `<img src="${item.path}" class="img-modal-text-view" />`);
		});
		setHtmlResult(str);
	}

	/* first option A preview */
	const onFirstOptionPreviewHandler = () => {
		setModal(true);
		let str = option;
		firstOptionArray.forEach((item, index) => {
			str = str.replace(`[${item.name}]`, `<img src="${item.path}" class="img-modal-text-view" />`);
		});
		setHtmlResult(str);
	}

	/* first option B preview */
	const onSecondOptionPreviewHandler = () => {
		setModal(true);
		let str = option2;
		secondOptionArray.forEach((item, index) => {
			str = str.replace(`[${item.name}]`, `<img src="${item.path}" class="img-modal-text-view" />`);
		});
		setHtmlResult(str);
	}

	/* first option C preview */
	const onThirdOptionPreviewHandler = () => {
		setModal(true);
		let str = option3;
		thirdOptionArray.forEach((item, index) => {
			str = str.replace(`[${item.name}]`, `<img src="${item.path}" class="img-modal-text-view" />`);
		});
		setHtmlResult(str);
	}

	/* first option D preview */
	const onFourthOptionPreviewHandler = () => {
		setModal(true);
		let str = option4;
		fourthOptionArray.forEach((item, index) => {
			str = str.replace(`[${item.name}]`, `<img src="${item.path}" class="img-modal-text-view" />`);
		});
		setHtmlResult(str);
	}

	/* image upload */
	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const formData = new FormData();
					loader.file.then((file) => {
						formData.append("tag_image", file);
						axios({
							url: API_URL,
							method: "POST",
							headers: {
								ContentType: "multipart/form-data",
							},
							data: formData,
						}).then((res) => {
							console.log('result', res.data.imagepath);
							resolve({
								default: res.data.imagepath
							});
						})
							.then((json) => {
								console.log('result2', json);
							})
					});
				});
			}
		};
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}


	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xxl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Exam category name</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																// value={category}
																value={qestionDetails.category}
																// onChange={(e) => setCategory(e.target.value)}
																onChange={setdata}
																name="category"
															>
																<option value="">Select category</option>
																{
																	props.categoryList.map((item, index) => {
																		return <option key={index} value={item.id}>{item.category}</option>
																	})
																}
															</select>
														</div>
														{errors.category && <div className="text-danger fs-12">{errors.category}</div>}
													</div>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Exam Type</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																value={qestionDetails.category ? qestionDetails.examType : ''}
																onChange={setdata}
																name="examType"
																disabled={qestionDetails.category ? false : true}
															>
																<option value="">Select Exam Type</option>
																{
																	qestionDetails.category == 1 ?
																		props.boardList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.name}</option>
																		})
																		:
																		props.examTypeList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.type_name}</option>
																		})
																}
															</select>
														</div>
														{errors.examType && <div className="text-danger fs-12">{errors.examType}</div>}
													</div>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Class</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																// value={qestionDetails.classNo}
																value={(qestionDetails.category == 1 && qestionDetails.examType) || (qestionDetails.category == 2 && qestionDetails.examType == 2) ? qestionDetails.classNo : ''}
																// onChange={(e) => setClassroom(e.target.value)}
																onChange={setdata}
																name="classNo"
																disabled={(qestionDetails.category && qestionDetails.examType) && (qestionDetails.category == 2 && qestionDetails.examType == 2) || (qestionDetails.category == 1 && qestionDetails.examType == 1 || qestionDetails.examType == 2) ? false : true}
															>
																<option value="">Select Class</option>
																{
																	props.classList.map((item, index) => {
																		return <option key={index} value={item.id}>Class {item.class_no}</option>
																	})
																}
															</select>
														</div>
														{errors.class && <div className="text-danger fs-12">{errors.class}</div>}
													</div>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Branch</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																value={qestionDetails.branch}
																// onChange={onBranchHandler}
																onChange={setdata}
																name="branch"
															>

																<option value="">Select Branch</option>
																{
																	props.branchList.map((item, index) => {
																		return <option key={index} value={item.id + '^' + item.branch_code}>{item.branch_name}</option>
																	})
																}
															</select>
														</div>
														{errors.branch && <div className="text-danger fs-12">{errors.branch}</div>}
													</div>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Chapter</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																value={qestionDetails.branch ? qestionDetails.chapter : ''}
																// onChange={(e) => setChapter(e.target.value)}
																onChange={setdata}
																name="chapter"
																disabled={qestionDetails.branch ? false : true}
															>
																<option value="">Select chapter</option>
																{
																	props.chapterList.map((item, index) => {
																		return <option key={index} value={item.id + '^' + item.short_code}>{item.chapter_name}</option>
																	})
																}
															</select>
														</div>
														{errors.chapter && <div className="text-danger fs-12">{errors.chapter}</div>}
													</div>

													<div className="form-group mb-3 position_relative">
														<label className="mb-2">
															<strong>Question Type</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={selectDefaultValue}
																className="form-control form-control"
																value={qestionDetails.questiontype}
																// onChange={(e) => setQuestiontype(e.target.value)}
																onChange={setdata}
																name="questiontype"
															>
																<option value="">Select Question type</option> classList
																<option value='1'>Question type 1</option>
																<option value='2'>Question type 2</option>
																<option value='3'>Question type 3</option>
															</select>
														</div>
														{errors.questiontype && <div className="text-danger fs-12">{errors.questiontype}</div>}
													</div>

													<div className="">
														<div className="form-group mb-3 position_relative">
															<label className="mb-2">
																<strong>Question No.</strong>
															</label>
															<input
																className="form-control"
																placeholder="Enter question No"
																value={qestionDetails.questiono}
																// onChange={(e) => setQuestiono(e.target.value)} 
																onChange={setdata}
																name="questiono"
															/>

															{errors.questiono && <div className="text-danger fs-12">{errors.questiono}</div>}
														</div>

														{/* ------------------ */}
														<div className="form-group mb-3">
															<div className="mb-2 image-preview-btn">
																<label>
																	<strong>Question Body</strong>
																</label>
															</div>

															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onFirstOptionPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>

															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={questionbody}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setQuestionbody(data);
																	}}

																/>
															</div>
															{errors.questionbody && <div className="text-danger fs-12">{errors.questionbody}</div>}
														</div>

														<div className="form-group mb-3">
															<label>
																<strong>Option A</strong>
															</label>
															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onFirstOptionPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>


															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={optionA}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setOptionA(data);
																	}}
																/>
															</div>
															{errors.optionA && <div className="text-danger fs-12">{errors.optionA}</div>}
														</div>

														<div className="form-group mb-3">
															<label>
																<strong>Option B</strong>
															</label>
															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onSecondOptionPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>

															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={optionB}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setOptionB(data);
																	}}
																/>
															</div>
															{errors.optionB && <div className="text-danger fs-12">{errors.optionB}</div>}
														</div>

														<div className="form-group mb-3">
															<label>
																<strong>Option C</strong>
															</label>
															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onThirdOptionPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>

															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={optionC}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setOptionC(data);
																	}}
																/>
															</div>
															{errors.optionC && <div className="text-danger fs-12">{errors.optionC}</div>}
														</div>

														<div className="form-group mb-3">
															<label>
																<strong>Option D</strong>
															</label>
															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onFourthOptionPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>


															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={optionD}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setOptionD(data);
																	}}
																/>
															</div>
															{errors.optionD && <div className="text-danger fs-12">{errors.optionD}</div>}
														</div>

														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Answers</strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
																<select
																	className="form-control form-control"
																	// value={answer}
																	value={qestionDetails.answer}
																	// onChange={(e) => setAnswers(e.target.value)}
																	onChange={setdata}
																	name="answer"
																>
																	<option value="">Select Answers</option>
																	<option value="A">A</option>
																	<option value="B">B</option>
																	<option value="C">C</option>
																	<option value="D">D</option>

																</select>
															</div>
															{errors.answer && <div className="text-danger fs-12">{errors.answer}</div>}
														</div>

														<div className="form-group mb-3">
															<label>
																<strong>Reason</strong>
															</label>
															<div className="image-preview-btn">
																<div className="tab-btn-view">
																	<div className='active-editor-btn radius-lft radius-right'>CK Editor</div>
																</div>
																<button onClick={onReasonPreviewHandler} type='button' className="editor-preview-btn">Preview</button>
															</div>


															<div className="ck-editer-view">
																<CKEditor
																	editor={ClassicEditor}
																	config={{
																		extraPlugins: [uploadPlugin],
																		toolbar: {
																			shouldNotGroupWhenFull: true,
																			items: [
																				"heading",
																				"fontsize",
																				"alignment",
																				"fontColor",
																				"fontBackgroundColor",
																				"outdent",
																				"indent",
																				"|",
																				"bold",
																				"italic",
																				"link",
																				"bulletedList",
																				"numberedList",
																				"imageUpload",
																				"mediaEmbed",
																				"insertTable",
																				"blockQuote",
																				"undo",
																				"redo",
																				"|",
																				"MathType",
																				"ChemType"
																			]
																		},
																		image: {
																			toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
																		},
																	}}
																	data={reason}
																	onReady={(editor) => {

																	}}
																	onChange={(event, editor) => {
																		const data = editor.getData();
																		setReason(data);
																	}}
																/>
															</div>
															{errors.reason && <div className="text-danger fs-12">{errors.reason}</div>}
														</div>
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>

				<Modal
					className="fade bd-example-modal-lg"
					show={modal}
					size="lg"
				>
					<Modal.Header>
						<Modal.Title>Modal title</Modal.Title>
						<Button
							variant=""
							className="btn-close"
							onClick={() => setModal(false)}
						>

						</Button>
					</Modal.Header>
					<Modal.Body>
						<div dangerouslySetInnerHTML={{ __html: htmlResult }} />
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="danger light"
							onClick={() => setModal(false)}
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal>

			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.questions.showLoading,
		categoryList: state.category.categoryList,
		branchList: state.branch.branchList,
		chapterList: state.chapter.chapterList,
		boardList: state.board.boardList,
		examTypeList: state.examtype.examTypeList,
		classList: state.class.classList,
	};
};
export default connect(mapStateToProps)(AddQuestions);