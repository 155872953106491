import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

import {
    logout,
    checkTokenAction,
} from '../store/actions/AuthActions';


export function signUp(name, email, user_type, phone, password) {

    const postData = {
        name,
        email,
        user_type,
        phone,
        password
    };
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.REGISTRATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function login(email, password) {

    const postData = {
        email,
        password,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.LOGIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function checkToken() {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_TOKEN_EXIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function updateProfile(name, email, phone, profile_pic) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("profile_pic", profile_pic);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROFILE_API,
        method: "POST",
        headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

export function changePassword(old_password, new_password) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        old_password,
        new_password,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "multipart/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function forgotPassword(email) {

    const postData = {
        email
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.FORGOT_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function formatError(errorResponse) {

    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {

    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    let tokenDetails = '';
    const tokenDetailsString = localStorage.getItem('userDetails');
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }
    tokenDetails = JSON.parse(tokenDetailsString);
    dispatch(checkTokenAction(tokenDetails, history));
}

export function resetPassword(message, password) {
    const postData = {
      message,
      password,
    };
  
    return axios({
      url: GlobalConfigs.API_URL + Apis.GENERATE_PASSWORD_API,
      method: "POST",
      headers: {
        ContentType: "application/json",
      },
      data: postData,
    });
  }

  export function getNotificationDetailsDetails() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;



    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_NOTIFICATION_DETAILS_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}

export async function getReadNotificationDetails(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
    }


    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_READ_NOTIFICATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}