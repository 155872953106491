import React, { Fragment, useRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import { Editor } from "@tinymce/tinymce-react";
import * as Apis from "../../../apis/Apis";
import axios from "axios";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import * as utility from "../../../utility/Utility";
import {
  uploadContentDesignerPDF,
  uploadContentDesignerConceptMap,
  finalUploadContentDesignerConceptMap,
} from "../../../store/actions/ElibraryActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["pdf"];

const ElibraryDetailsDesignerContentRequest = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const writeUp = localStorage.getItem("designerDetails");
  let errorsObj = { pdfFile: "", conceptmapFile: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [pdfFile, setPdfFile] = useState("");
  const [conceptmapFile, setConceptmapFile] = useState(
    state?.concept_map_File ? state?.concept_map_File : ""
  );
  const [content, setContent] = useState(state?.content_data);
  const [hyperlink, setHyperlink] = useState(
    state?.concept_map_data ? JSON.parse(state?.concept_map_data) : []
  );
  const [modalTerms, setModalTerms] = useState(false);
  const [file, setFile] = useState(null);
  const [conceptmapUploadFile, setConceptmapUploadFile] = useState(null);
  const [isCheck, setIsCheck] = useState(false);
  const [pdfPath, setPdfPath] = useState(
    state?.write_up_pdf ? state.write_up_pdf : ""
  );
  const [pdfPageNo, setPdfPageNo] = useState("");
  const [previewPath, setPreviewPath] = useState(state?.pdf ? state?.pdf : "");
  const [isSubmit, setIsSubmit] = useState(state?.pdf ? true : false);

  const [writeUpPdf, setWriteUpPdf] = useState(state?.write_up_pdf ? state.write_up_pdf : "");
  const [conceptMapPdf, setConceptMapPdf] = useState(state?.concept_map_pdf ? state.concept_map_pdf : "");

  useEffect(() => {
    console.log("state.......1", state.concept_map_File);
    return () => {
      localStorage.removeItem("designerDetails");
    };
  }, []);

  const onPDFhandler = (file) => {
    setConceptmapFile("");
    setPdfFile(file[0]);
    dispatch(globalLoadingAction(true));
    dispatch(
      uploadContentDesignerPDF(
        state.id,
        file[0],
        generateHyperlink,
        props.history
      )
    );
  };

  const generateHyperlink = (data) => {
    setPdfPageNo("");
    setPdfPath(data.file_path);
    setWriteUpPdf(data.file_path);
    setHyperlink(data.concept_map_data);
    localStorage.setItem("designerDetails",JSON.stringify(data.concept_map_data));
  };

  const onSubmitHandler = (e, status) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (state.write_up_pdf == "") {
      if (pdfFile === "" && state?.write_up_pdf === "") {
        errorObj.pdfFile = "Upload File is Required";
        error = true;
      }
    }

    if (conceptmapFile == "" && writeUp) {
      errorObj.conceptmapFile = "Concept Map File is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    if(status == 1){
      dispatch(finalUploadContentDesignerConceptMap(state.id,props.history));
    }else{
      dispatch(
        uploadContentDesignerConceptMap(
          state,
          conceptmapFile,
          status,
          uploadConceptMapCallback,
          props.history
        )
      );
    }
  };

  const uploadConceptMapCallback = (data) => {
    dispatch(globalLoadingAction(false));
    setPreviewPath(data.file_path);
    setConceptMapPdf(data.file_path);
    setIsSubmit(true);
  };

  const copyPath = (path) => {
    const conceptMapEncodePdfPath = base64_encode(path);
    utility.showSuccess("Link copied");
    navigator.clipboard.writeText(
      "https://crestest.com/" + conceptMapEncodePdfPath
    );
  };

  const onWriteupCopyPath = () => {
    if (pdfPageNo == "" || pdfPageNo == undefined) {
      utility.showError("Please put PDF page number");
      return;
    } else {
      const conceptMapEncodePdfPath = base64_encode(
        pdfPath + "#page=" + pdfPageNo
      );
      utility.showSuccess("Link copied");
      navigator.clipboard.writeText(
        "https://crestest.com/" + conceptMapEncodePdfPath
      );
    }
  };

  const onGoBackHandler = () => {
    history.replace({
      pathname: "/content-list",
      state: state?.filterData,
    });
  };

  const handleChange = (file) => {
    setFile(file);
    setPdfFile(file);
    onPDFhandler(file);
  };

  const handleChangeOnConceptmapUploadFile = (file) => {
    setIsSubmit(false);
    setConceptmapUploadFile(file);
    setConceptmapFile(file[0]);
  };

  const removeUploadFile = () => {
    setFile(null);
  };

  const removeUploadConceptFile = () => {
    setConceptmapFile("");
    setConceptmapUploadFile(null);
  };

  const fileSizeError = () => {
    removeUploadConceptFile();
  };

  const handleDownloaFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "concept_map_excel.xlsx"); // Optional: Set a default filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onPreViewHandler = () => {
    //let pdf = "https://crestestclv.s3.amazonaws.com/elibrary/library_pdf1686548725223.pdf";
    props.history.push({
      pathname: "/preview-pdf",
      state: {
        ...state,
        concept_map_File: conceptmapFile,
        pdf: previewPath,
        write_up_pdf: pdfPath,
        concept_map_data:JSON.stringify(hyperlink),
        concept_map_pdf:conceptMapPdf,
      },
    });
  };

  const getFilenameFromUrl = (url) =>
    url ? url.split("/").pop() : "No data found";

  const getFilename = (url) => (url ? url.split("/").pop() : "");

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="back-arrow-container">
            <Link onClick={onGoBackHandler}>
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card content-details">
              <div className="row">
                <div className="col-xl-6">
                  <div className="basic-details-item">
                    <label className="w-50">Category </label>
                    <span>{state?.exam_category}</span>
                  </div>

                  {state?.board_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Board </label>
                      <span>{state?.board_name}</span>
                    </div>
                  )}

                  {state?.subject && (
                    <div className="basic-details-item">
                      <label className="w-50">Subject </label>
                      <span>{state?.subject}</span>
                    </div>
                  )}

                  {state?.create_date && (
                    <div className="basic-details-item">
                      <label className="w-50">Create Date</label>
                      <span>
                        <Moment format="DD/MM/YYYY">{state?.create_date}</Moment>
                      </span>
                    </div>
                  )}

                  {state?.payment != null && (
                    <div className="basic-details-item">
                      <label className="w-50">Amount </label>
                      <span>{state?.payment}</span>
                    </div>
                  )}
                </div>

                <div className="col-xl-6">
                  {state?.chapter && (
                    <div className="basic-details-item">
                      <label className="w-50">Chapter</label>
                      <span>{state?.chapter}</span>
                    </div>
                  )}
                  {state?.class && (
                    <div className="basic-details-item">
                      <label className="w-50">Class</label>
                      <span>{state?.class}</span>
                    </div>
                  )}
                  {state?.exam_type_name && (
                    <div className="basic-details-item">
                      <label className="w-50">Type</label>
                      <span>{state.exam_type_name}</span>
                    </div>
                  )}
                  {state?.time_limit && (
                    <div className="basic-details-item">
                      <label className="w-50">Time Limit</label>
                      <span>
                        <Moment format="DD/MM/YYYY">{state.time_limit}</Moment>
                      </span>
                    </div>
                  )}
                  {state?.approved_status && (
                    <div className="basic-details-item">
                      <label className="w-50">Status</label>
                      <span>
                        {state.approved_status == 0 ? "Rejected" : "Accepted"}
                      </span>
                    </div>
                  )}
                </div>

                <div className="basic-details-item">
                  <label className="w-50">Write Up</label>
                  <div className="ck-editer-view">
                    <Editor
                      apiKey={GlobalConfigs.TINY_API_KEY}
                      value={content}
                      init={{
                        readonly: true, // Set the editor to read-only mode
                        height: 400,
                        menubar: true,
                        image_advtab: true,
                        external_plugins: {
                          tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                        },
                        // images_upload_handler: onImageHandler,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "print",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "paste",
                          "pagebreak",
                          "help",
                          "wordcount",
                          "codesample",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | pagebreak | help | image |" +
                          "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                        setup: (editor) => {
                          editor.ui.registry.addButton("pagebreak", {
                            text: "Page Break",
                            onAction: function (_) {
                              editor.execCommand(
                                "mceInsertContent",
                                false,
                                '<p style="page-break-before:always;"></p>'
                              );
                            },
                          });
                        },
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </div>

                <div className="basic-details-item">
                  <label className="w-50">
                    Upload Write Up File
                    {state?.write_up_pdf == "" ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <div className="file-input-container">
                    <div className="box__input">
                      <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes} /*  */
                        maxSize={10}
                      />

                      {!writeUpPdf?
                      <p className="text-center font-10">
                        {file
                          ? `File name: ${file[0].name}`
                          : "File Type (.pdf) and Size (10MB)"}
                      </p>
                      :null}

                      {writeUpPdf ? (
                        <p className="text-center">
                          {getFilename(writeUpPdf)}
                        </p>
                      ) : null}
                      <div id="error"></div>
                      <div id="file-name"></div>
                      {file ? (
                        <div
                          className="remove_btn"
                          onClick={() => removeUploadFile()}
                        >
                          Remove
                        </div>
                      ) : null}
                    </div>
                    <div className="pagination-wrapper px-0">
                      <label className="w-50">Hyperlink</label>
                      <ul>
                        {hyperlink.map((item, index) => {
                          return (
                            <li className="d-flex mx-0 mb-3">
                              <div className="col-10">
                                <input
                                  readonly="readonly"
                                  type="text"
                                  className="form-control"
                                  placeholder="Write something..."
                                  value={item.topic_heading}
                                />
                              </div>
                              <div className="col-2 d-flex">
                                <input
                                  readonly="readonly"
                                  className="form-control mx-3 text-center"
                                  placeholder="Page no"
                                  value={item.page_no}
                                  maxLength="3"
                                />
                                <button
                                  disabled={item.pdf_link ? false : true}
                                  className="btn btn-primary light px-2 btn-download"
                                  onClick={() => copyPath(item.pdf_link)}
                                >
                                  <i className="fa fa-copy m-0"></i>
                                </button>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="basic-details-item m-0 path_container">
                        <div className="pdf_left_side mt-2">
                          <label className="pe-1">PDF Path :</label>
                          {pdfPath + "#page="}
                          <input
                            type="text"
                            className="pdf_page_input"
                            placeholder="page no."
                            onChange={(e) => setPdfPageNo(e.target.value)}
                            value={pdfPageNo}
                            name="pdfpageno"
                            autoComplete="off"
                          />
                        </div>

                        <div className="pdf_right_side">
                          <button onClick={() => onWriteupCopyPath()}>
                            <i className="bi bi-bookmarks"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.pdfFile && (
                    <div className="text-danger fs-12">{errors.pdfFile}</div>
                  )}
                </div>

                <div className="basic-details-item">
                  <label className="w-50">
                    Concept Map (Before Design)
                    <span className="text-danger">*</span>
                  </label>
                  <div className="mb-3 position-relative">
                    <div
                      className="icon_right cursorPointer"
                      onClick={() => handleDownloaFile(state?.concept_map)}
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </div>
                    <div
                      className="form-control"
                      type="file"
                      onClick={() => handleDownloaFile(state?.concept_map)}
                    >
                      <strong>{getFilenameFromUrl(state?.concept_map)}</strong>
                    </div>
                  </div>
                  <div className="file-input-container">
                    <div className="box__input">
                      <FileUploader
                        disabled={!writeUp}
                        multiple={true}
                        handleChange={handleChangeOnConceptmapUploadFile}
                        name="fileConceptmap"
                        types={fileTypes}
                        maxSize={10}
                        onSizeError={fileSizeError}
                      />
                      <p className="text-center font-10">
                        {conceptmapUploadFile
                          ? `File name: ${conceptmapUploadFile[0].name}`
                          : "File Type (.pdf) and Size (10MB)"}
                      </p>
                      {conceptMapPdf ? (
                        <p className="text-center">
                          {getFilename(conceptMapPdf)}
                        </p>
                      ) : null}
                      <div id="error"></div>
                      <div id="file-name"></div>
                      {conceptmapUploadFile ? (
                        <div
                          className="remove_btn"
                          onClick={() => removeUploadConceptFile()}
                        >
                          Remove
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {errors.conceptmapFile && (
                    <div className="text-danger fs-12">
                      {errors.conceptmapFile}
                    </div>
                  )}
                </div>

                <div className="basic-details-item">
                  <div className="form-check">
                    <input
                      id="checkbox1"
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) => setIsCheck(e.target.checked)}
                      disabled={!isSubmit}
                      defaultValue={isCheck}
                    />
                    <label
                      htmlFor="checkbox1"
                      className="form-check-label custom-form-check-label"
                    >
                      I accept the{" "}
                      <a
                        href="javascript:void(0);"
                        className="link"
                        onClick={(e) => setModalTerms(true)}
                      >
                        Terms & Conditions
                      </a>
                    </label>
                  </div>
                </div>

                <div className="content-details-btn-groups mt-4">
                  <button
                    type="button"
                    onClick={(e) => onSubmitHandler(e, 1)}
                    className="btn btn-primary details-submit-btn"
                    disabled={!isCheck}
                    // disabled={!isSubmit}
                  >
                    Submit
                    {props.showLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                  </button>
                  <button
                    type="button"
                    disabled={isSubmit}
                    onClick={(e) => onSubmitHandler(e, 2)}
                    className="btn btn-primary details-submit-btn ms-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={(e) => onPreViewHandler()}
                    className="btn btn-primary details-submit-btn ms-2"
                    disabled={!isSubmit}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal className="fade" show={modalTerms}>
          <Modal.Header>
            <Modal.Title>Terms and Conditions</Modal.Title>
            <Button
              onClick={() => setModalTerms(false)}
              variant=""
              className="btn-close"
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <p>
              The authorized CLV LLP team member hereby covenants and agrees to
              treat as secret and confidential, to keep secure and not at any
              time for any reason to disclose or permit to be disclosed to any
              person or otherwise make use of or permit to be made use of any
              information relating to the CLV’s educational contents, technical
              processes, business affairs or finances or any such information
              relating to any member of the LLP or a supplier or customer or
              client of the LLP (Confidential Information). The team member also
              hereby agrees that any infringement or unauthorized use by them or
              caused by them of the LLP’s copyrights and Intellectual Property
              or Confidential Information, shall be held against them in any
              stringent legal recourse as may be deemed appropriate by the LLP.
              They also declare that every piece of content produced by them is
              entirely a product of their sole authorship, not plagiarized from
              any digital or non-digital sources, and true to the best of their
              knowledge.
            </p>
            <p>
              <strong>Note: </strong>
              Each piece of content will then be verified by Crestest admin, and
              in case of any error in terms of authenticity of the content, team
              members will instantly be notified and asked to review content
              under scrutiny. It is only after this final review that the
              content will be published.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    filePath: state.elibrary.filePath,
  };
};

export default connect(mapStateToProps)(ElibraryDetailsDesignerContentRequest);
