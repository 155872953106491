import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function getElibraryContentList(
  page,
  exam_category_id,
  board_id,
  exam_type_id,
  subject_id,
  class_no,
  chapter_id,
  status
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    exam_category_id,
    board_id,
    exam_type_id,
    subject_id,
    class_no,
    chapter_id,
    status,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONTENT_DEVELOPER_ELIBRARY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function uploadDeveloperFile(
  recid,
  content,
  concept_map,
  hyperlink,
  hyperlink_data,
  content_status,
  citation_no
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const formData = new FormData();
  formData.append("recid", recid);
  formData.append("content", content);
  formData.append("concept_map", concept_map);
  formData.append("hyperlink", hyperlink);
  formData.append("hyperlink_data", hyperlink_data);
  formData.append("content_status", content_status);
  formData.append("citation_no", citation_no);
  const postData = {
    recid,
    content,
    concept_map,
    hyperlink,
    hyperlink_data,
    content_status,
    citation_no,
  };
  console.log("postData.......", postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_CONTENT_DEVELOPER_ELIBRARY_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function finalUploadDesignerConceptmap(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let postData = {
    recid
  }
  return axios({
    url:GlobalConfigs.API_URL + Apis.SUBMIT_CONTENT_DESIGNER_CONCEPT_MAP_ELIBRARY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function uploadDesignerConceptmap(id, file, content_status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("concept_pdf_file", file);
  formData.append("content_status", content_status);
  formData.append("recid", id);
  // let postData = {
  //   id, file, content_status
  // }
  return axios({
    url:
      GlobalConfigs.API_URL +
      Apis.UPLOAD_CONTENT_DESIGNER_CONCEPT_MAP_ELIBRARY_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function developerApprovedRejectContent(recid, approve_status, reason) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
    approve_status,
    reason,
  };
  return axios({
    url:
      GlobalConfigs.API_URL +
      Apis.POST_DEVELOPER_CONTENT_APPROVED_AND_REJECT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function uploadDesignerPDF(id, file) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("pdf_file", file);
  formData.append("recid", id);
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function rejectReasons() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_REJECT_REASONS_API,
    method: "GET",
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}
