import React, {
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import * as Apis from "../../../apis/Apis";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  uploadSingleQuestionData,
  addAndNextSingleQuestionData,
  getQuestionsNumberByIdData,
} from "../../../store/actions/AssignQuestionsActions";
import {
  getQuestionTypeData,
  loadingQuestionType,
} from "../../../store/actions/QuestionTypeAction";

const UploadQuestions = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  let errorsObj = {
    quesType: "",
    quesBody: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    anws: "",
    resone: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [questiontype, setQuestiontype] = useState("");
  const [questionNo, setQuestionNo] = useState("");
  const [cssGroupId, setCssGroupId] = useState("");
  const [questiobody, setQuestiobody] = useState("");
  const [option, setOption] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [answers, setAnswers] = useState("");
  const [answersbody, setAnswersbody] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [reasonArray, setReasonArray] = useState([]);
  const [firstOptionArray, setFirstOptionArray] = useState([]);
  const [secondOptionArray, setSecondOptionArray] = useState([]);
  const [thirdOptionArray, setThirdOptionArray] = useState([]);
  const [fourthOptionArray, setFourthOptionArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [htmlResult, setHtmlResult] = useState("");
  const [questioStatus, setQuestioStatus] = useState(false);
  const [optionStatus, setOptionStatus] = useState(false);
  const [optionStatus2, setOptionStatus2] = useState(false);
  const [optionStatus3, setOptionStatus3] = useState(false);
  const [optionStatus4, setOptionStatus4] = useState(false);
  const [answersbodyStatus, setAnswersbodyStatus] = useState(false);

  useLayoutEffect(() => {
    dispatch(loadingQuestionType(true));
    dispatch(getQuestionTypeData(props.history));
  }, []);

  const onContinueHandler = (type, action) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (questiontype === "") {
      errorObj.quesType = "Question type is Required";
      error = true;
    }
    if (questiobody === "") {
      errorObj.quesBody = "Question is Required";
      error = true;
    }
    if (option === "") {
      errorObj.optionA = "Option A is Required";
      error = true;
    }
    if (option2 === "") {
      errorObj.optionB = "Option B is Required";
      error = true;
    }
    if (option3 === "") {
      errorObj.optionC = "Option C is Required";
      error = true;
    }
    if (option4 === "") {
      errorObj.optionD = "Option D is Required";
      error = true;
    }
    if (answers === "") {
      errorObj.anws = "Answers is Required";
      error = true;
    }
    if (answersbody === "") {
      errorObj.resone = "Reason is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(globalLoadingAction(true));
    if (action == 1) {
      dispatch(
        uploadSingleQuestionData(
          state.id,
          questiontype ? parseInt(questiontype) : 0,
          questionNo,
          cssGroupId,
          questiobody,
          option,
          option2,
          option3,
          option4,
          answers,
          answersbody,
          type,
          props.history
        )
      );
    } else {
      dispatch(
        addAndNextSingleQuestionData(
          state.id,
          questiontype ? parseInt(questiontype) : 0,
          questionNo,
          cssGroupId,
          questiobody,
          option,
          option2,
          option3,
          option4,
          answers,
          answersbody,
          type,
          onClearHandler,
          props.history
        )
      );
    }
  };

  const onClearHandler = () => {
    setQuestiontype("");
    setQuestionNo("");
    setCssGroupId("");
    setQuestiobody("");
    setOption("");
    setOption2("");
    setOption3("");
    setOption4("");
    setAnswers("");
    setAnswersbody("");
  };

  /* show que modal */
  const onQueModalHandlers = () => {
    setModal(true);
    let str = questiobody;
    imagesArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* show reason preview */
  const onReasonPreviewHandler = () => {
    setModal(true);
    let str = answersbody;
    reasonArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* show option A preview */
  const onFirstOptionPreviewHandler = () => {
    setModal(true);
    let str = option;
    firstOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* show option B preview */
  const onSecondOptionPreviewHandler = () => {
    setModal(true);
    let str = option2;
    secondOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* show option C preview */
  const onThirdOptionPreviewHandler = () => {
    setModal(true);
    let str = option3;
    thirdOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* show option D preview */
  const onFourthOptionPreviewHandler = () => {
    setModal(true);
    let str = option4;
    fourthOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* image upload */
  const onImageHandler = (blobInfo) => {
    return new Promise((success, reject) => {
      const formData = new FormData();
      formData.append("content_image", blobInfo.blob());
      axios({
        url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_IMAGE_API,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          success(res.data.imagepath);
        })
        .then((json) => {});
    });
  };

  /* navigation go back */
  const onGoBackHandler = () => {
    history.goBack();
  };

  /* upload question */
  const onQuestionTypeHandler = (val) => {
    setQuestiontype(val);
    if (val) {
      dispatch(
        getQuestionsNumberByIdData(
          state.id,
          val,
          setQuestionNoAndGroupId,
          props.history
        )
      );
    }
  };

  /* show question id */
  const setQuestionNoAndGroupId = (data) => {
    setQuestionNo(data.question_no);
    setCssGroupId(data.css_question_no);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card content-details">
            <div className="row">
              <div className="col-xl-2">
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler}>
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="form-group mb-3">
                      <label className="mb-2">
                        <strong>
                          Question Type
                          <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow add-Question-arrow-position">
                          <i className="bi bi-caret-down-fill"></i>
                        </span>
                        <select
                          defaultValue={"option"}
                          className="form-control form-control"
                          value={questiontype}
                          onChange={(e) =>
                            onQuestionTypeHandler(e.target.value)
                          }
                        >
                          <option value="">Select Question type</option>
                          {props.questionTypeList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.short_code}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.quesType && (
                        <div className="text-danger fs-12">
                          {errors.quesType}
                        </div>
                      )}
                    </div>

                    {questiontype == 5 && (
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          <strong>CSS Group Id</strong>
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          placeholder="Enter CSS group ID"
                          value={cssGroupId}
                          onChange={(e) => setCssGroupId(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label className="mb-2">
                        <strong>Question No.</strong>
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter question No"
                        value={questionNo}
                        onChange={(e) => setQuestionNo(e.target.value)}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Question Body
                            <span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={() => onQueModalHandlers()}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>

                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={questiobody}
                          onEditorChange={(newValue, editor) => {
                            setQuestioStatus(true);
                            setQuestiobody(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                          }}
                        />
                      </div>

                      {errors.quesBody && (
                        <div className="text-danger fs-12">
                          {errors.quesBody}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Option A<span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={onFirstOptionPreviewHandler}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>

                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={option}
                          onEditorChange={(newValue, editor) => {
                            setOptionStatus(true);
                            setOption(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>

                      {errors.optionA && (
                        <div className="text-danger fs-12">
                          {errors.optionA}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Option B<span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={onSecondOptionPreviewHandler}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>
                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={option2}
                          onEditorChange={(newValue, editor) => {
                            setOptionStatus2(true);
                            setOption2(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>
                      {errors.optionB && (
                        <div className="text-danger fs-12">
                          {errors.optionB}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Option C<span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={onThirdOptionPreviewHandler}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>
                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={option3}
                          onEditorChange={(newValue, editor) => {
                            setOptionStatus3(true);
                            setOption3(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>
                      {errors.optionC && (
                        <div className="text-danger fs-12">
                          {errors.optionC}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Option D<span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={onFourthOptionPreviewHandler}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>

                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={option4}
                          onEditorChange={(newValue, editor) => {
                            setOptionStatus4(true);
                            setOption4(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>

                      {errors.optionD && (
                        <div className="text-danger fs-12">
                          {errors.optionD}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label className="mb-2">
                        <strong>
                          Answers<span className="text-danger">*</span>
                        </strong>
                      </label>
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow">
                          <i className="bi bi-caret-down-fill"></i>
                        </span>
                        <select
                          className="form-control form-control"
                          value={answers}
                          onChange={(e) => setAnswers(e.target.value)}
                        >
                          <option value="">Select Answers</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </select>
                      </div>
                      {errors.anws && (
                        <div className="text-danger fs-12">{errors.anws}</div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <div className="image-preview-btn">
                        <label>
                          <strong>
                            Reason<span className="text-danger">*</span>
                          </strong>
                        </label>
                        <button
                          onClick={onReasonPreviewHandler}
                          type="button"
                          className="editor-preview-btn"
                        >
                          Preview
                        </button>
                      </div>
                      <div className="ck-editer-view">
                        <Editor
                          apiKey={GlobalConfigs.TINY_API_KEY}
                          value={answersbody}
                          // onInit={(evt, editor) => {
                          //     setAnswersbody(props.questionDetails.reason)
                          // }}
                          onEditorChange={(newValue, editor) => {
                            setAnswersbodyStatus(true);
                            setAnswersbody(newValue);
                          }}
                          init={{
                            height: 250,
                            menubar: false,
                            image_advtab: true,
                            images_upload_handler: onImageHandler,
                            external_plugins: {
                              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                            },
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help | image |" +
                              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>

                      {errors.resone && (
                        <div className="text-danger fs-12">{errors.resone}</div>
                      )}
                    </div>

                    <div className="content-details-btn-groups mt-4">
                      <button
                        type="button"
                        onClick={() => onContinueHandler(2, 1)}
                        className="btn btn-warning upload-btn"
                      >
                        Save & Draft
                      </button>
                      <button
                        type="button"
                        onClick={() => onContinueHandler(1, 1)}
                        className="btn btn-success upload-btn ms-2"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={() => onContinueHandler(1, 2)}
                        className="btn btn-primary upload-btn ms-2"
                      >
                        Add & Next
                      </button>
                      {/* <button
                        type="button"
                        onClick={() => onContinueHandler(1)}
                        className="btn btn-primary upload-btn ms-2"
                      >
                        Save & preview
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade bd-example-modal-lg" show={modal} size="lg">
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-editor">
            <Editor
              apiKey={GlobalConfigs.TINY_API_KEY}
              value={htmlResult}
              disabled={true}
              init={{
                height: 300,
                menubar: false,
                external_plugins: {
                  tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger light" onClick={() => setModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    questionTypeList: state.questionType.list,
    questionLoading: state.questionType.showLoading,
    questionDetails: [],
  };
};
export default connect(mapStateToProps)(UploadQuestions);
